'use client';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import Script from 'next/script';

import { ReactNode } from 'react';

import classNames from 'classnames';

import { Footer } from '@shared/common/components/Footer';
// import Header from '../Header';
import { NavLayout } from '@shared/common/components/Header/Header';
import { storePrimaryNav, storeQuickNavItems } from '@shared/common/components/Header/navSetting';
// import PolicyAndTerms from '@store:web/components/common/PolicyAndTerms/PolicyAndTerms';
import { useAuth } from '@shared/common/providers/AuthProvider';
import {
  useAuthModal,
  useBanxaModal,
  useSupportModal,
} from '@shared/common/providers/GlobalModalsProvider';
import { captchaContainerName } from '@shared/helpers/captcha';

import { MagicsquareLogo } from '@ui-uikit/lib/components/logos/MagicsquareLogo';

import { useGetBanner } from './hooks';

// const NotificationBannerOutlet = dynamic(
//   () =>
//     import('@store:web/components/providers/NotificationBannerProvider/NotificationBannerOutlet'),
//   { ssr: false },
// );
const NotificationBannerPortal = dynamic(
  () =>
    import('@store:web/components/providers/NotificationBannerProvider/NotificationBannerPortal'),
  { ssr: false },
);

const ClientSideHooks = dynamic(() => import('../ClientSideHooks/ClientSideHooks'), { ssr: false });

interface MainLayoutProps {
  children?: ReactNode;
}

export const NotificationBanner = () => {
  const pathname = usePathname();
  const { banner, className } = useGetBanner();

  const isHidden = ['/app/', '/developer/'].some((path) => pathname.startsWith(path));

  if (isHidden || !banner) {
    return null;
  }

  return (
    <NotificationBannerPortal>
      <div className={classNames('notification-banner', className)}>
        <div dangerouslySetInnerHTML={{ __html: banner }} />
      </div>
    </NotificationBannerPortal>
  );
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const { user, logout } = useAuth();
  const pathname = usePathname();
  const shouldHideHeaderOnLargeDevices = [
    // '/profile',
    // '/refer-a-friend',
    '/magic-boost',
    '/spaces',
  ].some((path) => pathname.startsWith(path));
  const modal = useBanxaModal();
  const { userEvmWallet } = useAuth();
  // const isMembershipSummaryPage = pathname === '/profile/membership/summary';
  // const isProfilePage = pathname.startsWith('/profile');

  const showFooter = !shouldHideHeaderOnLargeDevices;

  const authModal = useAuthModal();
  const supportModal = useSupportModal();

  const handleConnectClick = () => {
    authModal.open();
  };

  const handleBanxaBuyToken = () => {
    modal.open({
      walletAddress: userEvmWallet?.attributes.address,
    });
  };

  return (
    <>
      <div id={captchaContainerName} className="hidden" />

      <NotificationBanner />

      {/* <Sidebar className="max-lg:hidden" /> */}

      <NavLayout
        showMobileFeatureNav
        primaryNavMenuTitle="Magic Store"
        Logo={MagicsquareLogo}
        user={user ? { data: user } : undefined}
        onLogin={handleConnectClick}
        onLogout={logout}
        onSupport={supportModal.open}
        onBanxaBuyToken={handleBanxaBuyToken}
        primaryNavItems={storePrimaryNav}
        quickNavItems={storeQuickNavItems}
      >
        {/*
      <header className={classNames('fixed top-0 w-full left-0 z-header lg:pl-sidebar')}>
        <NotificationBannerOutlet
          className={classNames({
            'lg:pl-profile-sidebar': isProfilePage && !isMembershipSummaryPage,
          })}
        />

        <TopNavbar className="lg:hidden" />

        <Header
          className={classNames('max-lg:hidden', {
            'lg:hidden': shouldHideHeaderOnLargeDevices,
          })}
        />
      </header>

      <BottomNavbar className="fixed bottom-0 left-0 z-header lg:hidden" /> */}

        {/* NOTE: render it second time to occupy space for offset */}
        {/* <NotificationBannerOutlet
        aria-hidden="true"
        className={classNames('invisible shrink-0', {
          'lg:pl-sidebar-with-profile-sidebar': isProfilePage && !isMembershipSummaryPage,
          'lg:pl-sidebar': !isProfilePage || isMembershipSummaryPage,
        })}
      /> */}

        {/* NOTE: DO NOT SET OVERFLOW ON THIS ELEMENT IT CAN PRODUCT UNEXPECTED BUGS */}
        <div
          className={classNames(
            'w-full flex-auto flex flex-col',
            // 'pt-navbar  max-lg:pb-navbar',
            {
              // 'lg:pt-0': shouldHideHeaderOnLargeDevices,
              // 'lg:pt-header': !shouldHideHeaderOnLargeDevices,
            },
          )}
        >
          {children}

          {showFooter && <Footer user={user ?? null} />}
        </div>
      </NavLayout>

      <ClientSideHooks />
      {/* NOTE: not sure why we need this script */}
      <Script src="https://accounts.google.com/gsi/client" async defer strategy="lazyOnload" />
    </>
  );
};

export default MainLayout;
